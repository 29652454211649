@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrap {
  width: 100%;
  height: 100%;
}

.guide {
  padding-top: 23px;
  text-align: center;

  .title {
    color: var(--text-basic-default);
    @include font-size-18-bold();
    margin-bottom: 18px;
  }

  .description {
    display: flex;
    margin-bottom: 18px;
    padding: 0 20px;
    align-items: center;
    .text {
      color: var(--text-basic-default);
      @include font-size-16();
      margin-left: 12px;
      text-align: left;
      .bold {
        @include font-size-16-bold();
      }
    }
  }

  .image {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 18px;

    .image_content {
      display: flex;

      border-radius: 5px;
      background-color: #e1e1e1;

      $width: calc(100vw - 20px - 20px);

      width: $width;
      height: calc(#{$width} * 0.5375);
    }
  }

  .checkbox_wrap {
    margin: 24px 0px;
  }
}
