@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.button_gradient {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.button {
  height: 52px;
  margin-top: 16px;
}

.icon {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}

.payment_info {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    width: calc(100% - 20px - 20px);
    border: 1px solid var(--border-input-stroke-default);
    border-radius: 6px;
    padding: 15px 16px;
    @include touch-guide($borderRadius: 6px);

    .contents {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .icon {
        margin-right: 6px;
      }

      .name {
        font-size: 16px;
        line-height: 22px;

        &.error {
          color: var(--text-danger-default);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .maintenance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .left_wrapper {
        display: flex;
        align-items: center;

        color: #e55545;
        font-size: 16px;
        font-weight: 250;
        line-height: 22px;
        svg {
          margin-right: 4px;
          g {
            fill: #f24724;
          }
        }
      }
    }

    .text {
      color: var(--text-basic-caption);
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;

      white-space: nowrap;
    }
  }
}

.card + .card {
  margin-top: 6px;
}
