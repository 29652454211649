@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrap {
  width: 100%;
  text-align: center;

  i {
    display: inline-block;
    width: 42px;
    height: 42px;
    margin-top: 8px;
  }

  p {
    margin-top: 12px;
    font-size: 20px;
    font-weight: var(--F-weight-normal);
    line-height: 1.4;
    color: var(--text-basic-default);

    b {
      font-weight: var(--F-weight-bold);
    }
  }
}
