@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrap {
  position: relative;
  padding: 56px 0px 86px;
  background-color: var(--background-surface-primary-base);
  height: 100vh;

  h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 34px 20px;

    p {
      font-size: 20px;
      font-weight: var(--F-weight-normal);
      line-height: 1.35;
      letter-spacing: -0.1px;

      b {
        font-weight: var(--F-weight-bold);
      }
    }
  }

  .checkbox_area {
    margin: 0 36px 9px 36px;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    input[type='checkbox'] {
      display: none;
    }

    label {
      font-weight: var(--F-weight-normal);
      line-height: 1.13;
      letter-spacing: -0.2px;
      color: var(--text-basic-caption);
      opacity: 0.8;
      font-size: 15px;
      position: absolute;
      padding-left: 38px;
    }

    button {
      position: absolute;
      right: 0;
      width: 12px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .all {
    margin: 0px 20px 36px;
    padding: 16px 10px;
    border-radius: 8px;
    box-shadow: 0 10px 20px 2px rgba(84, 98, 115, 0.1);
    border: solid 1px #e1e3e9;

    label {
      font-size: 18px;
      font-weight: var(--F-weight-bold);
      line-height: 1.28;
      letter-spacing: normal;
      color: var(--text-basic-default);
    }
  }

  h3 {
    margin: 0 0 18px 36px;
    line-height: 1.13;
    letter-spacing: -0.2px;
    font-size: 16px;
    font-weight: var(--F-weight-normal);
  }

  .bottom {
    @include bottom_floating_area();
  }
}
