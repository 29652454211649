@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrapper {
  margin: 0 auto;
  z-index: $Z-overlay;
  width: calc(100vw - 50px);
  position: relative;
  max-width: 300px;
  min-width: 200px;

  .flicking_wrapper {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: var(--gray-100);
    border-radius: 8px 8px 0 0;
  }

  .count {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 11px;
    line-height: 16px;
    color: var(--white);
    z-index: 2;
    padding: 4px 10px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
  }

  .panel {
    width: 100%;
    height: 0;
    padding-bottom: 122.6%;
    position: relative;
    overflow: hidden;
    .item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .bottom_nav {
    display: flex;
    justify-content: space-between;
    padding: 17px 20px;
    font-size: 16px;
    font-weight: 250;
    line-height: 22px;
    background-color: var(--background-surface-floating-onMap);
    border-radius: 0 0 8px 8px;

    .today_hide {
      color: var(--text-basic-caption);
    }
    .hide {
      color: var(--text-basic-description);
    }
  }
}
