@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  .bubble_tail {
    height: 12px;
    path {
      fill: var(--gray-900);
    }
  }

  .container {
    border-radius: 8px;
    background: var(--gray-900);

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
