$base-font-family: TMOBI, sans-serif !default;
$base-font-weight: normal !default;

html {
  user-select: none;
  -webkit-user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
}

ul,
li,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p,
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

// https://tde.sktelecom.com/stash/projects/TMAPPOINEW/repos/tmap-search-web/pull-requests/428/overview
button {
  color: var(--text-basic-default);

  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: var(--text-basic-default);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--F-weight-normal);
  padding: 0;
  margin: 0;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

b {
  font-weight: var(--F-weight-normal);
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th {
  font-weight: var(--F-weight-normal);
  border: none;
  padding: 0;
}
