@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 16px 0 10px;
}

.icon_vehicle {
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: var(--color);
      }
    }
  }
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .item {
    width: 24px;
    height: 24px;

    &.border {
      img {
        width: inherit;
        height: inherit;
      }
    }

    .icon_company {
      width: 24px;
      height: 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &:not(:first-child) {
      margin-left: -4px;
    }

    &.active_item {
      display: flex;
      align-items: center;
      width: 100%;

      @include font-size-16-bold();
      color: var(--text-basic-default);

      height: 24px;

      .icon_company {
        margin-right: 8px;
      }
    }
  }
}

.text {
  @include font-size-14-bold();
  color: var(--text-basic-default);
  flex-shrink: 0;
  margin: 0 8px;

  letter-spacing: -0.04em;
}

.icon_check {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
}
