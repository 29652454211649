@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrapper {
  position: relative;
}

.tooltip_wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  box-sizing: border-box;
  padding: 12px 16px;
  background-color: var(--tmobi-blue-400);
  color: var(--white);
  border-radius: 8px;

  .close {
    margin-left: 4px;
    flex-shrink: 0;
    width: 22px;
    height: 22px;

    svg {
      width: 22px;
      height: 22px;
      path {
        fill: var(--white);
      }
    }
  }

  .arrow {
    position: absolute;
    width: 12px;
    height: 10px;
    svg {
      width: 12px;
      height: 10px;
      path {
        fill: var(--tmobi-blue-400);
      }
    }
    &.topRight {
      top: auto;
      bottom: 0;
      transform: translateY(13px) rotate(180deg);
      right: 24px;
    }
  }
}

.topRight {
  right: 0;
  top: -10px;
  transform: translateY(-100%);
}
