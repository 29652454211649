@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

.side_menu_button {
  width: 52px;
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;
}
