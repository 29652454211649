@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrapper {
  width: 100%;
  background-color: var(--background-surface-primary-elevated);
  color: var(--text-basic-default);

  padding: 20px;
  padding-top: 0;

  padding-bottom: calc(constant(safe-area-inset-bottom) + 16px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 16px);

  .header {
    display: flex;
    justify-content: space-between;
    padding: 6px 4px 0;
    margin-bottom: 24px;

    .scooter_info {
      display: flex;
      flex-wrap: wrap;
      .vehicle {
        flex-shrink: 0;
        color: var(--text-basic-default);
        @include font-size-16();
        margin-right: 8px;
      }

      .status {
        @include font-size-16-bold();

        color: var(--text-basic-accent);
      }
    }

    .battery_info {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-shrink: 0;
      margin-left: 4px;

      .battery_label {
        @include font-size-16();
        color: var(--text-basic-caption);
        margin-right: 4px;
      }

      .battery_percent {
        @include font-size-16-bold();
        color: var(--text-basic-default);
      }
    }
  }

  .trip_info {
    display: flex;
    justify-content: center;

    margin-bottom: 26px;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;

      text-align: center;
      line-height: 1.42;
      min-width: 88px;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }

      .value {
        @include font-size-28-bold();
        color: var(--text-basic-default);
      }

      .label {
        @include font-size-14();
        color: var(--text-basic-caption);
        margin-top: 2px;
      }
    }
  }

  .action {
    display: flex;

    * {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }

    .button_icon {
      width: 22px;
      height: 22px;
      margin-right: 4px;
    }
  }
}

@media screen and (max-width: 300px) {
  .wrapper {
    .header {
      .battery_info {
        flex-shrink: 1;
        .battery_label {
          margin-right: 0;
        }
      }
    }
  }
}
