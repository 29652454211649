@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.header_title {
  margin-bottom: 10px;
}

.report_warn_wrap {
  border: 1px solid var(--tmobi-blue-300);
  margin-bottom: 24px;

  span {
    em {
      color: #3a92ff;
    }
  }
}

.call_wrap {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 6px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    border-radius: 8px;
    padding: 20px 10px;
    height: 83px;

    & + .item {
      margin-top: 6px;
    }

    .button {
      display: flex;
      position: relative;
      @include touch-guide-for-circle-button($width: 32px);
    }
  }

  .company_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;

    .text {
      color: var(--text-basic-caption);
      font-size: 12px;
      line-height: 18px;
      margin-left: 30px;
    }
  }

  .title_wrap {
    display: flex;
    gap: 6px;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
      img {
        width: 24px;
        height: auto;
      }
    }

    .title {
      color: var(--gray-800);
      font-weight: var(--F-weight-bold);
      white-space: nowrap;
    }
  }
}

.guide_wrap {
  margin-top: 15px;
  padding: 0 5px;

  .guide_item {
    font-size: 14px;
    line-height: 20px;
    color: var(--text-basic-caption);
    margin-bottom: 10px;
  }
}

.customer_service_wrapper {
  background-color: #f5f9ff;
  padding: 20px 22px;
  border-radius: 0 16px 16px 16px;

  .customer_service_title {
    @include font-size-14-bold();
    color: var(--text-basic-description);
    margin-bottom: 10px;
  }

  .customer_service_description {
    @include font-size-14();
    color: var(--text-basic-caption);
    margin-bottom: 10px;
  }

  .insurance_button {
    @include font-size-13();
    background-color: var(--white);
    color: var(--gray-700);
    border: 1px solid var(--gray-200);
    border-radius: 4px;
    padding: 7px 10px;
  }
}

.accident {
  margin: 50px 0 20px;
  display: flex;
  align-items: center;
  span {
    margin-left: 3px;
    @include font-size-18-bold();
    color: var(--text-basic-default);
  }
}
