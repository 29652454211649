@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrapper {
  width: 100%;
  height: 100%;
}

.title {
  width: 100%;
  padding: 15px 0;
  text-align: center;
  @include font-size-20-bold();
  color: var(--text-basic-default);
}

.content {
  padding: 20px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    height: 74px;
    border-radius: 8px;
    border: 1px solid var(--background-surface-tertiary-base, #eef0f3);
    &.checked {
      border: 1px solid var(--background-button-primary);

      .check_wrapper {
        svg {
          path {
            stroke: #0064ff;
          }
        }
      }
    }

    .description_wrapper {
      .sales_contents {
        @include font-size-16-bold();
        color: var(--text-basic-default);
        margin-bottom: 2px;
      }
      .event_title {
        @include font-size-12();
        color: var(--text-basic-caption);
      }
    }
  }
}

.item + .item {
  margin-top: 8px;
}

.footer {
  padding: 20px 20px 16px;
  button {
    width: 100%;
    background-color: var(--background-button-primary);
    color: var(--text-button-primary);
    @include font-size-18();
    border-radius: 8px;
    padding: 14px 0;
  }
}
