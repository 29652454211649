@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

.header {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.body {
  margin-top: 24px;
  margin-bottom: 40px;
  padding: 0 20px;

  .title {
    text-align: center;
    font-size: 20px;
    color: var(--gray-900);
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .message {
    text-align: center;
    color: var(--text-danger-default);
    font-size: 16px;
    line-height: 26px;
  }
}

.footer {
  display: flex;
  padding: 0 20px 20px;
  .close_button {
    width: 104px;
    flex-shrink: 0;
  }

  button + button {
    margin-left: 8px;
  }
}
