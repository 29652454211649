@charset "UTF-8";

@import '../../styles/@tmds/tmdsfonts';
@import '../../styles/@tmds/tmdsmixin';

@import '../../styles/common/index';

@import '../../styles/basics/variables';
@import '../../styles/basics/mixins';
@import '../../styles/basics/functions';
@import '../../styles/basics/extends';
@import '../../styles/basics/keyframes';

@import 'styles/basics';

.title {
  padding: 6px 0 20px;
  @include font-size-20-bold();
  color: var(--text-basic-default);
}
