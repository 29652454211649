@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.page_wrap {
  width: 100%;
  height: 100%;
  overflow: scroll;

  .item_beforeuse {
    width: 50px;
    margin-right: 20px;
  }

  .item_using {
    width: 50px;
    margin-right: 20px;
  }

  .item_return {
    width: 74px;
    margin-right: 16px;
  }

  .item_report {
    width: 100px;
    margin-right: 0;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
  }

  .content_wrap {
    // 헤더와 tabs까지의 높이를 합친 값만큼 margin-top
    margin-top: 102px;
  }
}
