@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.list_wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  font-size: 16px;
  height: 46px;
  padding: 0 15px;
  border-bottom: 1px solid var(--border-line-default);
  line-height: 1.25;

  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    font-size: 16px;
    line-height: 1.25;
    color: var(--text-basic-caption);
    margin-right: 20px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: var(--text-basic-accent);
      font-weight: var(--F-weight-bold);
    }
  }

  .active_bar {
    position: absolute;
    height: 2px;
    background: var(--text-basic-accent);
    width: 100%;
    bottom: 0;
  }
}
