@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.guide_title {
  margin-top: 40px;
  margin-bottom: 10px;
}

.detail_wrap {
  i {
    width: 20px;
    height: 20px;

    margin-right: 4px;
  }

  .title {
    display: flex;
    align-items: center;
    height: 52px;

    font-size: 16px;
    line-height: 1.13;
    letter-spacing: -0.2px;
    color: var(--text-basic-default);
  }
}

.item {
  margin-bottom: 50px;
  margin-top: 50px;
}

.desc_item {
  color: var(--text-basic-caption);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-right: 10px;

  .dot {
    font-size: 14px;
  }

  .list {
    font-size: 14px;
  }
}

.item_title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin-right: 6px;
  }
}

.caution_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  width: 100%;
  height: 62px;
  background-color: var(--yellow-50);
  margin-top: 30px;
  border-radius: 12px;
  padding: 0 10px 0 24px;

  .caution_text_wrapper {
    display: flex;
    align-items: center;
    .caution_text {
      margin-left: 8px;
      color: var(--text-basic-description);
      @include font-size-14-bold();
    }
  }
}
