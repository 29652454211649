@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;

  &:disabled {
    background-color: var(--background-button-disabled);
    color: var(--text-button-disabled);
    box-shadow: none;
  }
}

.large {
  padding: 14px 0;
  font-size: 18px;
}

.primary {
  color: var(--text-button-primary);
  background-color: var(--background-button-primary);
  border: none;
  &:active {
    background-color: $C-primary-active;
    color: var(--text-button-primary);
  }
}

.secondary {
  color: var(--text-button-secondary);
  background-color: var(--background-button-secondary);
}

.gray-stroke {
  color: var(--text-button-secondary);
  border: 1px solid var(--border-button-stroke-default);

  &:active {
    background-color: $C-white-active;
    color: var(--text-button-secondary);
  }
}

.blue-naked {
  border: none;
  background-color: inherit;
  color: var(--text-button-accent);

  &:active {
    color: var(--text-button-accent);
  }
}
