@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

.wrapper {
  padding-left: 12px;
  .title {
    display: flex;
    align-items: center;
  }
  .key_text {
    color: #741687;
    font-weight: bold;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}
