@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrap {
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--gray-100);

  .action {
    width: 100%;
    position: fixed;
    bottom: 0;
  }

  .image {
    padding-top: 56px;
  }
}
