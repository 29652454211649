@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrapper {
  margin: 20px 0 4px;
  padding: 0 20px;
  position: relative;
  width: 100%;

  .top_content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .title {
      display: flex;
      align-items: center;

      @include font-size-18-bold();
      color: var(--text-basic-default);

      .title_text {
        margin-right: 2px;
      }

      .text_list {
        padding-inline-start: 14px;
        .list_item {
          list-style: disc;

          &::marker {
            font-size: 8px;
          }

          .link {
            color: var(--text-basic-accent);
            text-underline-offset: 2px;
            text-decoration-line: underline;
          }
        }
      }

      svg {
        path {
          fill: var(--icon-tertiary);
        }
      }
    }
  }

  .tooltip {
    width: 236px;
    box-sizing: border-box;
    right: -12px;
  }

  .controller {
    display: flex;
    align-items: center;

    .description {
      @include font-size-16-bold();
      color: var(--text-basic-disabled);
      margin-right: 8px;

      &.active {
        color: var(--text-basic-accent);
      }
    }
  }

  .take_point {
    @include font-size-12();
    display: flex;
    justify-content: flex-end;

    color: var(--text-basic-caption);

    .point {
      @include font-size-12-bold();
      margin-left: 3px;
    }
  }
}
