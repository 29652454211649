@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.button_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: var(--F-weight-normal);
  padding-bottom: calc(env(safe-area-inset-bottom) + 16px);

  &.background {
    background-color: var(--background-surface-primary-elevated);
  }
  .bottom_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    border-radius: 5px;
    line-height: 1.29;
  }
}

.gradient {
  @include fade-out-gradient($height: 20px, $top: -20px, $direction: top);
  left: 0;
  right: 0;
}
