@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.build_info_wrap {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 0, 0, 0.7);
  word-break: break-all;
  z-index: 100000;

  .info_wrap {
    display: none;
  }

  &.view_detail {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);

    .info_wrap {
      background: white;
      display: flex;
      flex-direction: column;
      margin: 30px 5px 0;
      max-height: calc(100% - 80px - 30px);
      border-radius: 5px;
      overflow-y: scroll;

      .list_item {
        border-bottom: 1px solid #ccc;
        padding: 5px 20px 7px;

        &:active {
          background-color: #eee;
        }

        &.table_data,
        &.array_data {
          &:active {
            background-color: inherit;
          }
        }

        &:last-child {
          border-bottom: 0 none;
        }

        h3 {
          color: #aaa;
          font-size: 12px;
          padding: 0;
          margin: 0 0 3px 0;
        }

        .data_wrap {
          display: flex;
          flex-direction: column;
          min-height: 12px;
          font-size: 14px;

          &.desc {
            margin: 10px 0px;

            p {
              color: rgba(0, 0, 0, 0.5);
              font-size: 3px;
            }
          }

          &.performance_data {
            flex-direction: row;
            padding: 5px 0px;

            .timestamp {
              color: rgba(0, 0, 0, 0.5);
              font-size: 3px;
            }

            :first-child {
              flex-basis: 50%;
            }

            :nth-child(2) {
              flex-basis: 30%;
              flex-grow: 0;
              text-align: center;
            }

            :nth-child(3) {
              font-weight: var(--F-weight-black);
              text-align: center;
              flex-grow: 1;
            }
          }
        }

        &.array_data .data_wrap {
          margin-bottom: 2px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.table_data .data_wrap {
          display: table;
          border-collapse: collapse;
          width: 100%;

          .key_value_data {
            display: table-row;
            border-bottom: 1px solid #eee;

            &:active {
              background-color: #eee;
            }

            &:last-child {
              border: 0 none;
            }
          }

          .table_header {
            color: blue;
            display: table-cell;
            padding: 5px 5px 5px 0;
            white-space: nowrap;
          }

          .table_value {
            word-break: break-all;
            padding: 5px 0;
          }
        }
      }
    }

    .end_item {
      padding: 20px 0;
      text-align: center;
      color: #ccc;
    }
  }
}

.btn_env {
  color: red;
  border: 1px solid red;
  font-size: 10px;
  padding: 3px;

  position: fixed;

  right: 10px;
  bottom: 50px;
}

.input_wrap {
  display: flex;
  justify-content: space-between;

  .title {
    min-width: 80px;
  }

  input {
    flex: 1;
    width: 100px;
  }

  button {
    background-color: #ccc;
    margin-left: 5px;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 12px;
  }
}

.storage_wrap {
  font-size: 14px;
  padding: 10px;

  li {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    &.keys {
      flex-direction: column;

      p {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }

      p:first-child {
        font-size: inherit;
      }
    }
  }

  button {
    background-color: #ccc;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 12px;
  }

  button + button {
    margin-left: 5px;
  }
}
