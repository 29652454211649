@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.scroll_wrap {
  overflow: scroll;
  height: 100vh;
  background-color: var(--background-surface-primary-base);
  position: relative;
}

.wrap {
  padding-top: 56px;
  height: 100%;
}
