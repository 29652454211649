@charset "UTF-8";

@import '../styles/@tmds/tmdsfonts';
@import '../styles/@tmds/tmdsmixin';

@import '../styles/common/index';

@import '../styles/basics/variables';
@import '../styles/basics/mixins';
@import '../styles/basics/functions';
@import '../styles/basics/extends';
@import '../styles/basics/keyframes';

.wrapper {
  display: flex;
  .dot_wrapper {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .dot {
    width: 4px;
    height: 4px;
    content: ' ';
    background-color: var(--text-basic-caption);
    border-radius: 100%;
  }

  .text {
    @include font-size-14();
    color: var(--text-basic-caption);
  }
}
