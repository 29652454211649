@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

.wrapper {
  position: relative;
  overflow: hidden;

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // 컨텐츠 높이만큼 위로 올리는데 1px씩 남는 현상이 있어서 index * 1px 씩 더 올리도록 계산식 넣음
    transform: translateY(calc(-100% * var(--index) - 1px * var(--index)));
  }
}
