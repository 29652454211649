@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrap {
  display: flex;
  flex-wrap: wrap;

  .input_area {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;

    input {
      font-size: 17px;
      border: none;
      outline: none;
      border-bottom: 1px solid $C-input-line;
      padding: 12px 0;
      color: var(--text-basic-default);
      font-weight: var(--F-weight-bold);
      background: var(--background-surface-primary-base);
      line-height: 1.41;

      &::placeholder {
        font-weight: var(--F-weight-normal);
        color: var(--text-basic-caption);
      }

      &:focus {
        border-bottom: 1px solid var(--border-input-stroke-accent);
      }
    }

    .license_number {
      display: flex;

      li {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        &:nth-child(1) {
          flex: 1 0 25%; // (74 / (296)) * 100
        }
        &:nth-child(2) {
          flex: 1 0 14.8%; // (44 / (296)) * 100
        }
        &:nth-child(3) {
          flex: 1 0 29%; // (86 / (296)) * 100
        }
        &:nth-child(4) {
          flex: 1 0 14.8%; // (44 / (296)) * 100
        }

        input {
          text-align: center;
          width: 100%;
        }

        .dash {
          padding: 0 4px;
        }
      }
    }

    label {
      color: $C-label-text;
      font-size: 12px;
      font-weight: var(--F-weight-normal);
      height: 16px;
    }
  }

  .dropdown_button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 48px;
    border-bottom: 1px solid $C-input-line;

    font-weight: var(--F-weight-normal);
    font-size: 17px;
    color: var(--gray-300);

    &.has_data {
      color: var(--text-basic-default);
      font-weight: var(--F-weight-bold);
    }

    .icon {
      width: 19px;
      height: 19px;

      path {
        stroke: var(--icon-primary);
      }
    }
  }
}

.layer_wrap {
  width: 100%;
  height: 100%;

  .list {
    overflow-y: scroll;
    max-height: 80vh;

    .item {
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 16px 20px;
      width: 100%;
      height: 56px;
      font-size: 18px;

      @include touch-guide();

      &.is_selected {
        color: var(--tmobi-blue-500);
        font-weight: var(--F-weight-bold);
      }
    }
  }
}

// https://tmobi.atlassian.net/browse/TMAPPM-408
@media only screen and (max-device-width: 345px) {
  $font-size: 12.5px;

  .wrap {
    .input_area {
      input {
        font-size: $font-size;
      }
    }

    .dropdown_button {
      font-size: $font-size;
    }
  }
}
