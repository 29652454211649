@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  width: 90px;
  height: 90px;
  margin-right: 20px;
  svg {
    width: 90px;
    height: 90px;
  }
}

.content_wrap {
  display: flex;
  flex-direction: column;

  .title {
    @include font-size-18-bold();
    margin-bottom: 8px;
    color: var(--text-basic-description);
  }

  .desc {
    color: var(--text-basic-caption);
    @include font-size-14();
  }
}
