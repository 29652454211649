@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.dimmed {
  @include dimmed();
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: $Z-overlay;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, var(--sat) - var(--sab));
  transition: transform ease 0.5s;
  will-change: transform;

  &.bottom {
    transform: translate(0, 100%);
  }

  * {
    z-index: inherit;
  }

  &.hide {
    display: none;
  }
}
