@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.map_wrap {
  background-color: #ddd;
  background-image: url('../../resource/images/map-bg-img-type-1.jpg');
  background-size: contain;

  @media (-webkit-min-device-pixel-ratio: 2) {
    background-image: url('../../resource/images/map-bg-img-type-1@2x.jpg');
  }

  @media (-webkit-min-device-pixel-ratio: 3) {
    background-image: url('../../resource/images/map-bg-img-type-1@3x.jpg');
  }
}
