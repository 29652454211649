@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.info {
  margin: 12px 20px;
  padding-bottom: 80px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    @include font-size-16();

    .item_name {
      color: var(--text-basic-caption);
    }

    .item_value {
      color: var(--text-basic-default);
    }

    .total_name {
      @include font-size-16();
      color: var(--text-basic-default);
    }

    .total_money {
      @include font-size-18-bold();
      color: var(--text-basic-accent);
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--border-line-default);
    margin: 20px 0;
  }
}
