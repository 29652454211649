@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrap {
  position: relative;
}

.skeleton {
  position: absolute;
  top: 0;
  width: 100%;

  opacity: 1;
  @include skeleton-wrap;

  &.is_hide {
    opacity: 0;
  }
}

.skeleton__vehicle_card {
  display: grid;
  grid-template:
    'first img'
    'second img'
    'third img';

  padding: 20px;
  padding-top: 2px;

  .grid_first {
    grid-area: first;
    width: 220px;
    height: 24px;

    margin-bottom: 6px;

    @include skeleton-item;
  }

  .grid_second {
    grid-area: second;
    width: 144px;
    height: 18px;

    margin-bottom: 14px;

    @include skeleton-item;
  }

  .grid_third {
    grid-area: third;
    width: 192px;
    height: 18px;

    @include skeleton-item;
  }

  .grid_img {
    grid-area: img;
    width: 78px;
    height: 78px;
    margin: 0 0 0 auto;
    margin-top: 2px;

    @include skeleton-item;
  }
}
