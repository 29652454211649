@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrap {
  position: relative;
  padding-top: 56px;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-surface-primary-base);
  overflow: none;

  .holder {
    position: relative;

    .info_text {
      padding: 79px 0 22px;
      text-align: center;
      font-size: 14px;
      line-height: 1.43;
      color: var(--text-basic-description);
    }

    .input_wrap {
      padding: 0 56px;

      .button_wrap {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 20px;
        margin-bottom: 22px;
      }
    }

    .bottom_button_wrap {
      position: fixed;
      width: 100vw;
    }

    .arrow_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      margin-left: 4px;
      height: inherit;
      transition: transform 0.2s ease-in;

      &.is_selected {
        transform: rotate(-180deg);
      }
    }
  }
}

.select_layer_wrap {
  width: 100%;
  height: 100%;

  .container {
    padding-top: 8px;
    padding-bottom: 20px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 20px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    height: 56px;
    @include font-size-18();

    &.is_selected {
      @include font-size-18-bold();
    }
  }
}

.is_disabled_button {
  background-color: $C-disabled-button;
  color: $C-disabled-text;
  pointer-events: none;
}
