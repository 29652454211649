@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrapper {
  display: flex;
  align-items: center;
  position: relative;

  width: 44px;
  height: 28px;
  overflow: visible;
  box-sizing: border-box;

  .rect {
    width: 44px;
    height: 20px;

    border-radius: 10px;

    background-color: var(--icon-quaternary);
  }

  .circle {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    background-color: var(--icon-inverse);
    border: 1px solid var(--icon-quaternary);
    border-radius: 100%;
  }

  &.checked {
    .circle {
      right: 0;
      left: auto;
      border: 1px solid var(--background-success-primary);
    }
    .rect {
      background-color: var(--background-success-primary);
    }
  }

  &.disabled {
    .circle {
      background-color: var(--background-button-secondary);
      border: 1px solid var(--background-success-disabled);
    }
    .rect {
      background-color: var(--background-success-disabled);
    }

    &.checked {
      .circle {
        background-color: var(--background-button-secondary);
        border: 1px solid var(--background-success-disabled);
        right: 0;
        left: auto;
      }
      .rect {
        background-color: var(--background-success-disabled);
      }
    }
  }

  &.small {
    width: 32px;
    height: 20px;

    .rect {
      width: 32px;
      height: 14px;
    }
    .circle {
      width: 20px;
      height: 20px;
    }
  }
}
