@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

$top-gap: 8px;

.round_button {
  $side-gap: 12px;

  position: fixed;
  top: $top-gap;
  z-index: $Z-header;

  background-color: var(--background-surface-floating-onMap);
  border-radius: 50%;
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  @include floating_shadow();
  @include touch-guide-for-circle-button($width: 48px);

  &.left {
    left: $side-gap;
  }

  &.right {
    right: $side-gap;
  }

  .back_button {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      pointer-events: none;
    }

    &.icon_back {
      margin-right: 2px;
    }
  }
}

.button_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: $top-gap 0;
  z-index: $Z-dropdown;
  width: 100%;

  .list_button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    height: 40px;
    background: var(--background-surface-floating-onMap);
    border-radius: 24px;

    @include floating_shadow();

    &.is_closed {
      @include touch-guide($padding: 9px 11px, $borderRadius: 23px);
      letter-spacing: -0.5px;
    }

    .popover {
      position: fixed;
      top: 54px;
    }
  }
}
