@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.guide_wrap {
  padding: 20px;
}

.guide_item {
  margin-bottom: 40px;
}

.title {
  display: flex;
  align-items: center;

  height: 40px;
  margin-bottom: 12px;

  @include font-size-18-bold();
  color: var(--text-basic-description);
  letter-spacing: -0.45px;
}

.warn_wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px var(--red-200);
  margin-bottom: 30px;

  span {
    line-height: 1.43;
    font-size: 14px;
    text-align: center;
    color: var(--text-basic-default);

    em {
      font-style: normal;
      font-weight: var(--F-weight-bold);
      color: var(--text-danger-default);
    }
  }
}

.bar {
  width: 100%;
  height: 1px;
  background-color: var(--border-line-default);
}

.icon_wrap {
  position: relative;

  .icon {
    position: absolute;
    right: 0;
    top: 0;

    width: 20px;
    height: 20px;
  }
}
