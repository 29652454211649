@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.bottom {
  @include bottom_floating_area();

  $floating-x: 11px;
  $floating-y: -13px;

  .position {
    position: absolute;
    left: $floating-x;
    top: $floating-y;
    transform: translateY(-100%);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    @include floating_shadow();
    @include touch-guide-for-circle-button();
    background-color: var(--background-surface-floating-onMap);
  }

  .guide {
    display: flex;
    position: absolute;
    right: $floating-x;
    top: $floating-y;
    transform: translateY(-100%);

    padding: 10px 14px 10px 12px;
    border-radius: 23px;

    background: var(--background-surface-primary-base);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

    @include floating_shadow();
    @include touch-guide($borderRadius: 23px);

    span {
      font-weight: var(--F-weight-bold);
      font-size: 14px;
      line-height: 20px;
      margin-left: 2px;
      letter-spacing: -0.04em;
    }
  }

  .bottom_sheet {
    position: relative;
    box-sizing: border-box;

    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.2);
    padding-top: 0px;

    .contents_wrap {
      padding-top: 18px;
      &.is_riding {
        padding-top: 24px;
      }
    }
  }
}

.flicking_wrap {
  width: 100%;
  overflow: hidden;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 12px;
  bottom: 75px;
}

.bar {
  width: calc(100% - 40px);
  margin: 7px 20px 8px;
  border-top: 1px solid $C-gray-button;
}
