@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.viewer_wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $Z-image-viewer;
}

.image_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: black;

  img {
    width: 100%;
    height: calc(100vh - 112px);
    object-fit: cover;
    padding: 20px 0px;
  }
}

.header {
  background: black;
  color: white;
}

.back_button {
  g {
    path {
      fill: white;
    }
  }
}
