@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.main_page_wrap {
  * {
    font-size: 13px;

    button {
      text-align: left;
      margin: 2px;

      &::before {
        content: '[클릭]';
        margin-right: 5px;
        background-color: antiquewhite;
      }
    }
  }
  h2 {
    margin-top: 10px;
    background-color: khaki;
  }
  .item {
    padding: 5px;

    &:nth-child(even) {
      background-color: #e8e8e8;
    }
  }
}
