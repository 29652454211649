@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.dev_info_wrap {
  position: fixed;
  z-index: $Z-dev-map-info;
  top: 150px;
  right: 0px;
  padding: 10px;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 1px solid white;
}
