@charset "UTF-8";

@import '../styles/@tmds/tmdsfonts';
@import '../styles/@tmds/tmdsmixin';

@import '../styles/common/index';

@import '../styles/basics/variables';
@import '../styles/basics/mixins';
@import '../styles/basics/functions';
@import '../styles/basics/extends';
@import '../styles/basics/keyframes';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
}

.wrapper + .wrapper {
  border-top: 1px solid var(--palette-gray-strong);
}
