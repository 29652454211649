@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrapper {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: auto;
  max-height: 440px;
  width: 310px;

  .content {
    flex-basis: 100%;
    flex-shrink: 0;
    max-height: 390px;
    padding: 35px 20px;
    text-align: center;
    background-color: var(--background-surface-primary-elevated);
    word-break: keep-all;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .detail {
      margin-top: 14px;

      font-weight: var(--F-weight-normal);
      font-size: 15px;
      line-height: 1.47;
      color: var(--text-basic-caption);

      mark {
        color: var(--text-basic-accent);
        background-color: inherit;
      }

      em {
        font-weight: var(--F-weight-bold);
        color: var(--text-danger-default);
      }
    }

    .title {
      font-weight: var(--F-weight-normal);
      font-size: 18px;
      line-height: 1.33;
      color: var(--text-basic-default);

      mark {
        color: var(--text-basic-accent);
        background-color: inherit;

        b {
          font-weight: var(--F-weight-bold);
        }
      }
    }
  }

  .actions {
    height: 50px;
    display: flex;
    width: 100%;

    button {
      flex-basis: 100%;
      font-size: 16px;
      border-radius: 0;

      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}
