@import './basics/index';
@import './common/index';

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  color: var(--text-basic-default);
}

* {
  box-sizing: border-box;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}
