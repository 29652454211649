@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrapper {
  display: flex;
  align-items: center;
  width: calc(100% - 36px);

  .description {
    width: 100%;
    margin-left: 6px;
    @include font-size-16();
    color: var(--text-basic-default);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }
}
