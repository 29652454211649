@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.title_wrap {
  margin-bottom: 62px;

  .fail_message_wrap {
    color: var(--text-danger-default);
    font-size: 14px;
    text-align: center;
    margin-top: 8px;
  }
}

.divider {
  height: 1px;
  background-color: var(--border-line-default);
  margin: 0 20px;
}

.retry_guide {
  padding: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: var(--text-basic-caption);

  li {
    position: relative;
    margin-bottom: 16px;
    padding-left: 15px;

    &::before {
      position: absolute;
      content: '•';
      left: 0px;
    }
  }
}

.bottom {
  @include bottom_floating_area();
  background-color: var(--background-surface-primary-base);

  .card_change {
    margin-bottom: 8px;

    i {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }
}

.item_wrapper {
  padding-bottom: 20px;
  .item {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    @include font-size-16();

    .item_name {
      color: var(--text-basic-caption);
    }

    .amount {
      @include font-size-16-bold();
      color: var(--text-basic-accent);
    }

    + .item {
      margin-top: 8px;
    }
  }
}
