@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.link_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    text-align: center;
    margin: 30px 0 10px;
  }

  .link_box {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    text-align: center;

    &:hover {
      background-color: #ccc;
    }
  }
}
