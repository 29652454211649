@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

.wrap {
  display: flex;
  min-width: 0;
  align-items: center;

  i {
    flex-shrink: 0;
  }

  span {
    &:first-child {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 4px 0 6px;
    }
  }
}
