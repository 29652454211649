// https://tmobi.atlassian.net/wiki/spaces/TMDS/pages/156535563/Skeleton
@keyframes blink {
  50% {
    opacity: 0.3;
  }
}

@mixin skeleton-item {
  border-radius: 4px;
  background: linear-gradient(to right, var(--black-alpha-200), var(--black-alpha-50));
  animation: blink 2s ease-in-out infinite;
}

@mixin skeleton-wrap {
  transition: opacity ease-in-out 0.3s;
  background-color: var(--white);
}
