@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.select_button {
  display: flex;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;

    img {
      width: inherit;
      height: inherit;
    }
  }

  .icon_company {
    margin-right: 8px;
  }

  .text {
    &.selected {
      color: var(--tmobi-blue-500);
    }
  }
}
