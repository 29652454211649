@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.marker_wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 32px;
}

.round {
  padding: 6px 10px;
  border-radius: 16px;
  border: solid 1px #2d62ba;
  background-color: var(--tmobi-blue-500);

  .text {
    color: var(--gray-100);
    line-height: 1.29;
    height: 18px;
    font-size: 14px;
  }
}

.bar_wrap {
  display: flex;
  justify-content: center;
  position: relative;

  .bar {
    width: 2px;
    height: 16px;
    background-color: #2d62ba;
  }

  .shadow {
    width: 12px;
    height: 3px;
    opacity: 0.3;
    background-color: $C-sub-text-dark;
    position: absolute;
    bottom: -2px;
    border-radius: 80%;
  }
}
