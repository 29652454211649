@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.icon {
  position: relative;

  &.is_active {
    .vehicle {
      i {
        top: 7px;
        left: 7px;
        right: 7px;

        width: 36px;
        height: 36px;

        svg {
          path {
            fill: var(--white);
          }
        }
      }

      .marker {
        display: flex;
        flex-direction: column;
        align-items: center;

        .shadow {
          width: 10px;
          height: 3px;
          margin-top: -1px;
          margin-left: -1px;
        }
      }
    }

    .company {
      width: 22px;
      height: 22px;
    }

    .battery {
      width: 18px;
      height: 12px;
    }

    .discount {
      width: 33px;
      height: 22px;
      right: -10px;
    }
  }

  .vehicle {
    position: relative;

    i {
      position: absolute;
      top: 6px;
      left: 6px;
      right: 6px;

      width: 24px;
      height: 24px;

      svg {
        width: inherit;
        height: inherit;
      }
    }
  }

  .company {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 16px;
    height: 16px;

    img {
      width: inherit;
      height: inherit;
    }
  }

  .battery {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 2px;
    right: 0;

    width: 12px;
    height: 8px;

    svg {
      width: inherit;
      height: inherit;
    }
  }

  .discount {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    right: -8px;
  }
}

.count_wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.08);

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--background-surface-floating-onMap);

    .number {
      @include font-size-16-bold();
      color: var(--text-basic-caption);
    }
  }
}

.is_active {
  z-index: $Z-active-marker;
}
