@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.wrap {
  width: 100vw;
  height: 100vh;
  background: var(--background-surface-primary-base);
  position: fixed;
  z-index: $Z-try-rent;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: calc(40vh - 64px); //(100vh-160) * 0.4

  .image {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
  }

  .comment {
    display: flex;
    flex-direction: column;

    color: var(--text-basic-caption);
    @include font-size-16();

    b {
      color: var(--text-basic-description);
      margin-bottom: 8px;

      @include font-size-18-bold();
    }
  }
}
