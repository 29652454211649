@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.personal_mobility_wrap {
  height: 100%;
  width: 100%;
}

.prototype_wrap {
  height: 100%;
  width: 100%;
  overflow: scroll;
}
