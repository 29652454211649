@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px -2px 4px 0px var(--white-alpha-200);
  padding-top: 12px;
  background-color: var(--background-surface-primary-base);
  z-index: 1001;
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  position: fixed;
}

.dimmed {
  @include dimmed();
  visibility: visible;
  opacity: 0.6;
}
