@charset "UTF-8";

@import '../../styles/@tmds/tmdsfonts';
@import '../../styles/@tmds/tmdsmixin';

@import '../../styles/common/index';

@import '../../styles/basics/variables';
@import '../../styles/basics/mixins';
@import '../../styles/basics/functions';
@import '../../styles/basics/extends';
@import '../../styles/basics/keyframes';

@import 'styles/basics';

.wrapper {
  + .wrapper {
    margin-top: 50px;
  }
}

.title {
  @include font-size-18-bold();
  color: var(--text-basic-description);
}

.description {
  @include font-size-14();
  color: var(--text-basic-caption);
  margin-top: 8px;
}

.image {
  margin-top: 20px;
  img {
    width: 100%;
    height: auto;
  }
}
