@charset "UTF-8";

// # name Convention
//  $F = font
//  $C = color
//  $Z = z-index

// https://zpl.io/VOL3wZ8
$C-primary-active: #0059e5;

$C-sub-text-dark: #4d4d4d;
$C-disabled-text: #d8d8d8;
$C-label-text: #909090;

$C-gray-button: #f0f0f0;
$C-disabled-button: $C-gray-button;
$C-gray-button-active: #d7d7d7;

$C-white-active: #e6e6e6;

$C-default-line: #f1f1f1;

$C-input-line: #eeeeee;

$C-button-border: #c6cad2;

$C-transparent: rgba(255, 255, 255, 0);

$C-border-color: var(--gray-100);

$C-code-background: #f5f5f5;

$Z-overlay: 51;
$Z-dimmed: 50;
$Z-side-bar: 1300;
$Z-try-rent: 1000;
$Z-image-viewer: 1500;
$Z-event-layer: 2000;

$Z-header: 30;
$Z-floating: 30;
$Z-gradient: 30;
$Z-dropdown: 20;

$Z-active-marker: 10;
$Z-dev-map-info: 1000;
