@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.header_wrap {
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
  background-color: var(--background-surface-primary-base);
  z-index: $Z-header;

  &.gradient::after {
    width: 100%;
    height: 7px;
    content: '';
    opacity: 0.6;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 70%);
    position: absolute;
    pointer-events: none;
  }

  h1 {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: var(--F-weight-bold);
      font-size: 18px;
      color: var(--text-basic-default);
    }
  }

  .side {
    position: absolute;
    height: 100%;
    top: 0;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }
}
