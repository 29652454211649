@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.notice_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 49px;
  padding: 0 14px;

  .text_wrap {
    display: flex;
    align-items: center;
    width: calc(100% - 16px);

    .icon_alert {
      display: flex;
      align-items: center;
      margin-right: 6px;
    }
  }

  .icon {
    font-weight: var(--F-weight-black);
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;

    height: 24px;
    padding: 3px 6px;
    margin-right: 6px;
    border-radius: 12px;

    &.notice {
      color: var(--green-500);
      border: 1px solid var(--green-500);
    }

    &.benefit {
      color: var(--tmobi-pink-500);
      border: 1px solid var(--tmobi-pink-500);
    }

    &.info {
      color: var(--tmobi-blue-500);
      border: 1px solid var(--tmobi-blue-500);
    }
  }

  .text {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-basic-default);

    em {
      font-weight: var(--F-weight-bold);
    }
  }
}

.rolling_wrap {
  height: 49px;
  background-color: var(--background-surface-secondary-base);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  border-bottom: 1px solid var(--border-line-default);
}
