@charset "UTF-8";

// # template
// @mixin mixinName($varName: block) {
//   &:before {
//     display: $varName;
//   }
//   display: $varName;
// }

// # use case
// div {
//   @include mixinName(flex);
// }

// # result
// div {
//   &:before {
//     display: flex;
//   }
//   display: flex;
// }

@mixin fade-out-gradient($height: 15px, $top: 0, $bottom: 0, $color: #fff, $direction: bottom) {
  content: '';
  position: absolute;
  width: 100%;
  height: $height;
  top: $top;
  bottom: $bottom;
  z-index: $Z-gradient;
  background: linear-gradient(to $direction, $color 0%, $C-transparent 100%);
}

@mixin bottom_floating_area() {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $Z-floating;
}

@mixin dimmed {
  opacity: 0;
  visibility: hidden;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $Z-dimmed;

  background-color: var(--black-alpha-300);
}

@mixin floating_shadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

@mixin touch-guide(
  $borderRadius: '',
  $top: 0,
  $left: 0,
  $right: 0,
  $bottom: 0,
  $opacity: 0.1,
  $padding: initial
) {
  &:active:after {
    content: ' ';
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
    padding: $padding;
    background-color: #000;
    opacity: $opacity;
    @if $borderRadius != '' {
      border-radius: $borderRadius;
    }
    pointer-events: none;
  }
}

@mixin touch-guide-for-circle-button($opacity: 0.1, $top: 0px, $left: 0px, $width: 46px) {
  &:active:after {
    content: ' ';
    position: absolute;
    top: $top;
    left: $left;
    width: $width;
    height: $width;
    background-color: #000;
    opacity: $opacity;
    border-radius: 25px;
    z-index: 10;
    pointer-events: none;
  }
}
