@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.tooltip_wrapper {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  position: absolute;
  left: 20px;

  // 모바일 사이즈 - 양쪽 패딩
  width: calc(100vw - 40px);
  padding: 16px;

  border: 1px solid var(--border-button-stroke-default);
  border-radius: 4px;
  background-color: var(--background-surface-primary-elevated);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);

  &.bottom {
    bottom: -4px;
    transform: translateY(100%);
  }

  &.top {
    top: -4px;
    transform: translateY(-100%);
  }

  .title {
    @include font-size-14-bold();
    color: var(--text-basic-description);

    &.is_no_sub_text {
      @include font-size-14();
      color: var(--text-basic-caption);
    }
  }

  .sub_text {
    @include font-size-12();
    margin-top: 4px;
    color: var(--text-basic-caption);
  }

  .close_button {
    flex-shrink: 0;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--icon-tertiary);
      }
    }
  }
}
