@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrap {
  position: relative;
  padding: 56px 0px 86px;
  overflow: scroll;
  width: 100%;
  height: 100%;
  background-color: var(--background-surface-primary-base);

  .guide {
    width: 100%;
    padding: 32px 0px 40px;
    text-align: center;

    .image {
      margin: auto;
      width: 183px;
      height: 110px;
    }

    p {
      &:first-of-type {
        margin-top: 20px;
      }

      display: flex;
      align-items: center;
      justify-content: center;

      line-height: 1.43;
      font-weight: var(--F-weight-normal);
      font-size: 14px;
      color: var(--text-basic-caption);

      i {
        margin-right: 3px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .input {
    padding: 0px 32px;
  }

  .bottom {
    @include bottom_floating_area();
  }
}
