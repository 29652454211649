@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.wrapper {
  height: 100%;
  overflow: scroll;
}

.content {
  @include font-size-14();
  padding: 20px;
  color: var(--text-basic-caption);

  .main_title {
    @include font-size-16-bold();
    color: var(--text-basic-default);
    margin-bottom: 8px;
  }

  .sub_title {
    @include font-size-14-bold();
    color: var(--text-basic-description);
    margin-bottom: 8px;
  }

  .paragraph {
    margin-bottom: 20px;
  }

  table {
    margin-bottom: 40px;
    text-align: center;

    thead {
      color: var(--text-basic-description);
      background-color: var(--gray-100);
      tr {
        th {
          width: 25%;
          height: 32px;
          @include font-size-12-bold();
        }
      }
    }

    tbody {
      .row_title {
        white-space: nowrap;
        background-color: var(--gray-50);
      }

      tr {
        td {
          border: 1px solid var(--border-line-default);
          padding: 8px 10px;
        }
      }
    }
  }

  ul {
    @include font-size-14();
    padding-inline-start: 24px;

    &.ul_margin {
      margin-bottom: 8px;
    }

    li {
      list-style: disc;
    }
  }
}
