@charset "utf-8";

@mixin tmobi-font($size: 14, $bold: false, $lineHeight: 1.43) {
  @if $bold == true {
    font-weight: var(--F-weight-bold);
  } @else {
    font-weight: var(--F-weight-normal);
  }
  font-size: $size;
  line-height: $lineHeight;
}

@mixin font-size-28-bold() {
  @include tmobi-font(28px, true, 1.4);
}
@mixin font-size-28() {
  @include tmobi-font(28px, false, 1.4);
}

@mixin font-size-24-bold() {
  @include tmobi-font(24px, true, 1.33);
}
@mixin font-size-24() {
  @include tmobi-font(24px, false, 1.33);
}

@mixin font-size-20-bold() {
  @include tmobi-font(20px, true, 1.4);
}
@mixin font-size-20() {
  @include tmobi-font(20px, false, 1.4);
}

@mixin font-size-18-bold() {
  @include tmobi-font(18px, true, 1.33);
}
@mixin font-size-18() {
  @include tmobi-font(18px, false, 1.33);
}

@mixin font-size-16-bold() {
  @include tmobi-font(16px, true, 1.38);
}
@mixin font-size-16() {
  @include tmobi-font(16px, false, 1.38);
}

@mixin font-size-15-bold() {
  @include tmobi-font(15px, true, 1.33);
}
@mixin font-size-15() {
  @include tmobi-font(15px, false, 1.33);
}

@mixin font-size-14-bold() {
  @include tmobi-font(14px, true, 1.43);
}
@mixin font-size-14() {
  @include tmobi-font(14px, false, 1.43);
}

@mixin font-size-13-bold() {
  @include tmobi-font(13px, true, 1.53);
}
@mixin font-size-13() {
  @include tmobi-font(13px, false, 1.53);
}

@mixin font-size-12-bold() {
  @include tmobi-font(12px, true, 1.5);
}
@mixin font-size-12() {
  @include tmobi-font(12px, false, 1.5);
}

@mixin font-size-11-bold() {
  @include tmobi-font(11px, true, 1.45);
}
@mixin font-size-11() {
  @include tmobi-font(11px, false, 1.45);
}
