@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.content {
  padding: 0px 20px 20px 20px;
  margin-top: -9px;
  color: var(--text-basic-caption);
  letter-spacing: -0.2px;
  opacity: 0.8;
  line-height: 1.4;
  font-size: 14px;

  h1 {
    margin: 20px 0px 12px 0px;
    letter-spacing: 0px;
    opacity: 1;
    font-size: 15px;
    color: var(--text-basic-description);
    font-weight: var(--F-weight-bold);
  }

  .description {
    color: var(--text-basic-description);
  }

  .link {
    @include font-size-14-bold();
    color: var(--text-basic-accent);
    margin-left: 2px;
  }

  .vertical_table {
    margin: 24px 0px;
    font-size: 12px;
    line-height: 18px;

    .list {
      padding-left: 16px;
    }

    div {
      display: flex;
      border: solid 1px var(--border-line-default);

      &:not(:last-child) {
        border-bottom: none;
      }
    }

    .table_title {
      display: flex;
      align-items: center;
      padding: 8px;
      color: var(--text-basic-description);
      background-color: var(--gray-50);
      border-right: solid 1px var(--border-line-default);
      font-size: 12px;
      width: 86px;
      flex-shrink: 0;
    }

    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 8px;
    }
  }

  .table {
    margin: 24px 0px;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;

    div {
      flex: 1 1 50%;
      line-height: 1.54;
      letter-spacing: -0.17px;
      border: solid 1px var(--border-line-default);
      color: var(--text-basic-description);

      h3 {
        padding: 8px 0;
        text-align: center;
        border-bottom: solid 1px var(--border-line-default);
        background-color: var(--gray-50);
      }

      p {
        font-size: 13px;
        min-height: 80px;
        padding: 11px 10px 14px 10px;
      }

      &:nth-child(1) {
        margin-bottom: 24px;
      }

      &:nth-child(2) {
        margin-bottom: 24px;
      }

      &:nth-child(odd) {
        border-right: none;
      }
    }
  }

  ul {
    li {
      margin-bottom: 8px;
      color: var(--text-basic-caption);
      font-size: 14px;
      line-height: 20px;

      b {
        opacity: 0.8;
        font-weight: var(--F-weight-bold);
      }
    }
  }

  // https://tmobi.atlassian.net/browse/TMAPPM-603
  // 이용약관 특정 부분에 accent를 줘야하는 부분이 있음
  .accent {
    font-weight: 500;
    color: var(--text-basic-description) !important;
  }
}
