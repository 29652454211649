@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.side_wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--background-surface-primary-elevated);
  z-index: $Z-side-bar;
  will-change: transform;
  width: 270px;
  height: 100vh;
  transform: translateX(100%);
  transition: transform ease-in-out 0.35s;

  &.is_show {
    transform: translateX(0);
  }
}

.menu_wrap {
  margin-top: 36px;
  height: 100%;
  overflow: scroll;
  padding-bottom: 100px;

  .item {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 58px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    .title {
      font-size: 18px;
      letter-spacing: normal;
      line-height: 24px;
      color: var(--text-basic-default);
      font-weight: normal;
    }
  }

  .bar {
    height: 1px;
    margin: 8px 0 8px 20px;
    background-color: var(--border-line-default);
  }
}

.dimmed {
  @include dimmed();

  will-change: opacity;
  transition: opacity 0.3s ease-in;

  &.is_show {
    visibility: visible;
  }

  &.animation {
    opacity: 0.35;
  }
}
