@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

.wrap {
  width: 100%;
  height: 100%;
}

.guide {
  padding-top: 22px;
  text-align: center;

  .image {
    margin: auto;
    width: 94px;
    height: 80px;
  }

  h3 {
    margin-top: 16px;
    font-weight: var(--F-weight-bold);
    line-height: 1.33;
    color: var(--text-basic-default);
    font-size: 18px;
  }

  p {
    margin: 12px 0px 35px;
    line-height: 1.33;
    color: var(--text-basic-description);
    font-size: 15px;
  }
}
