@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

.wrapper {
  .label {
    margin-bottom: 14px;
    font-size: 14px;
    color: var(--text-basic-caption);
  }

  .input_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    .input {
      outline: none;
      border: none;
      width: 100%;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      color: var(--text-basic-default);
      background-color: inherit;
      padding: 14px 40px 12px 0;
      box-sizing: border-box;
      border-bottom: 2px solid var(--border-input-stroke-default);

      &:focus {
        border-bottom: 2px solid var(--border-input-stroke-accent);
      }

      &::placeholder {
        font-weight: normal;
        color: var(--text-basic-placeholder);
      }
    }

    .icon {
      position: absolute;
      right: 16px;
      top: 20px;
    }
  }
}
