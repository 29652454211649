@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';

.card_wrap {
  display: flex;
  padding: 0 20px;
  padding-bottom: 16px;
  justify-content: space-around;
}

.desc_wrap {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  height: 87px;

  .price {
    display: flex;
    margin-top: 12px;
    color: var(--text-basic-caption);
    @include font-size-14();

    i {
      display: flex;
      align-items: center;
      margin-right: 4px;
    }

    em {
      font-style: normal;
      color: var(--text-basic-accent);
    }
  }
}

.title_wrap {
  display: flex;
  font-weight: var(--F-weight-bold);
  white-space: nowrap;
  align-items: center;

  .title {
    margin-right: 6px;
    color: var(--text-basic-default);
    @include font-size-18-bold();
  }
}

.battery_wrap {
  display: flex;
  align-items: center;

  @include font-size-14();

  margin-top: 5px;
  height: 22px;

  color: var(--text-basic-default);

  .battery {
    display: flex;
    align-items: center;

    @include font-size-14-bold();

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 22px;
      height: 22px;
      margin-right: 5px;

      svg {
        width: 18px;
        height: 12px;
      }
    }

    .text {
      line-height: 20px;
    }

    &:has(~ .time) {
      &::after {
        content: '\A';
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: var(--text-basic-default);
        display: inline-block;
        margin: 4px;
      }
    }
  }

  .time {
    overflow: hidden;
    width: calc(100% - 75px);
    text-overflow: ellipsis;

    @include font-size-14();
  }
}

.image_wrap {
  display: flex;
  align-items: center;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 78px;
    height: 78px;
    object-fit: cover;
    border-radius: 8px;
    background-color: var(--gray-100);

    svg {
      width: 54px;
      height: 54px;

      path {
        fill: var(--icon-quaternary);
      }
    }
  }
}

@media (max-width: 320px) {
  .desc_wrap {
    width: calc(100% - 72px);
  }

  .image_wrap {
    img {
      width: 72px;
      height: 72px;
    }
  }
}
