@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.bi {
  display: inline-flex;
  min-width: 24px;
  height: 18px;
  border-radius: 2px;

  background-color: var(--gray-400);

  align-items: center;
  justify-content: center;
}
