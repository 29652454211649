@charset "UTF-8";

@import '../styles/@tmds/tmdsfonts';
@import '../styles/@tmds/tmdsmixin';

@import '../styles/common/index';

@import '../styles/basics/variables';
@import '../styles/basics/mixins';
@import '../styles/basics/functions';
@import '../styles/basics/extends';
@import '../styles/basics/keyframes';

@import 'styles/basics';

.wherego_text {
  color: var(--text-basic-inverse);
  margin-right: 2px;
  @include font-size-14-bold();
}

.event_target {
  background: linear-gradient(132deg, #3d8bff 8.67%, #34c7c2 102.91%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include font-size-14-bold();
}
