@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.main_page_wrap {
  .company_list {
    position: fixed;
    top: 56px;
    padding-top: 12px;
    justify-content: center;
    width: 100%;
    display: flex;
    z-index: $Z-floating;
    overflow-x: scroll;

    &.has_banner {
      top: 92px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .floating_comment {
    top: 56px;
    position: fixed;
    left: calc(50vw);
    width: fit-content;
    transform: translateX(-50%);
    margin-top: 12px;
    z-index: $Z-floating;

    &.has_banner {
      top: 92px;
    }
  }

  .banner {
    width: 100%;
    font-size: 13px;
    line-height: 1.46;
    color: #666;
    text-align: center;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    z-index: $Z-header;
    top: 56px;
    left: 0;
    border-top: 1px solid $C-border-color;
    border-bottom: 1px solid $C-border-color;
  }
}
