@charset "UTF-8";

@import '../@tmds/tmdsfonts';
@import '../@tmds/tmdsmixin';

@import '../common/index';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';

@import 'styles/basics';

.item {
  display: flex;
  + .item {
    margin-top: var(--gap);
  }
}

.list_wrap {
  @include font-size-14();

  .text {
    font-size: inherit;
    color: var(--text-basic-caption);
    width: fit-content;
  }

  .dot {
    width: 4px;
    height: 4px;
    margin: 8px;
    margin-left: 0px;
    background-color: var(--text-basic-caption);
    border-radius: 50%;

    &.is_round {
      border-radius: 50%;
    }
  }
}
