@charset "UTF-8";

@import '../../@tmds/tmdsfonts';
@import '../../@tmds/tmdsmixin';

@import '../../common/index';

@import '../../basics/variables';
@import '../../basics/mixins';
@import '../../basics/functions';
@import '../../basics/extends';
@import '../../basics/keyframes';

@import 'styles/basics';
.first_wrapper {
  margin-bottom: 50px;
}
.sub_title {
  display: flex;
  align-items: center;

  font-size: 17px;
  font-weight: var(--F-weight-bold);
  line-height: 1.53;
  letter-spacing: -0.45px;
  color: var(--text-basic-default);
}

.warn_list_wrap {
  margin-bottom: 40px;

  .sub_title {
    margin-bottom: 16px;
  }

  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 400px;

    .item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      width: calc((100vw - 40px - 16px) / 2);

      margin-bottom: 20px;

      border-radius: 4px;
      border: solid 1px var(--border-line-default);

      .image {
        min-height: 100px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: var(--border-line-default);
        object-fit: cover;
      }

      &:nth-child(2n-1) {
        margin-right: 16px;
      }

      &:nth-child(n + 7) {
        margin-bottom: 0;
      }

      .text_wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        max-height: 60px;
        min-height: 42px;
        padding: 12px 0;

        font-size: 14px;
        line-height: 1.29;
        color: var(--text-basic-default);
        text-align: center;
      }
    }
  }
}

.warn_text_wrap {
  margin-bottom: 40px;

  .sub_title {
    margin-bottom: 10px;
  }

  .guide_text {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: var(--text-basic-default);
  }
}

.bullet_item {
  display: flex;

  i {
    margin-right: 4px;
  }
}

.notice {
  font-size: 13px;
  line-height: 1.54;
  text-align: left;
  color: var(--text-basic-caption);

  margin-top: 20px;
}

.sub_title {
  display: flex;
  align-items: center;
  @include font-size-18-bold();
  color: var(--text-basic-default);
  span {
    margin-left: 4px;
  }
}

.best_case_wrapper {
  margin-top: 20px;
  margin-bottom: 62px;
}

.worst_case_wrapper {
  margin-top: 30px;
}

.warning_wrapper {
  background-color: var(--palette-red-lighter);
  padding: 20px 22px;
  border-radius: 0 16px 16px 16px;
  margin: 16px 0 30px;

  .warning_title {
    @include font-size-14-bold();
    color: var(--text-basic-description);
    margin-bottom: 10px;
  }
}

.danger {
  color: #ca2c0c;
}

.footer_description {
  margin-top: 50px;
  padding-top: 40px;
  border-top: 1px solid var(--gray-200);
  margin-bottom: 100px;
}
